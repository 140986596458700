@font-face {
  font-family: 'Gluten';
  src: url('../public/fonts/Gluten-Regular.ttf');
}

body, html, #root, #r3f-wrapper {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#r3f-wrapper {
  background-color: #2f2a4c;
  background-image: linear-gradient(180deg, #2f2a4c 0%, #8c80d1 50%, #FEA5AD 100%);
}

.buy-now{
  font-size: 14px;
  color: white;
  cursor: pointer;
  padding: 10px 16px;
  font-family: "Gluten", serif;
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: inherit;
  background-image: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 1s;
  font-family: "Gluten", serif;
}

.loadingScreen--started {
  opacity: 0;
  pointer-events: none;
}

.loadingScreen--started .loadingScreen__board {
  opacity: 0;
}

.sunflowerLoader {
  width: 250px;
  height: 250px;
  animation:spin 8s linear infinite;
}

.loadingLabel{
  color: white;
  margin-top: 30px;
  font-size: 30px;
  text-align: center;
  padding: 10px;
}

.loadingButton {
  margin-top: 30px;
  font-family: "Gluten", serif;
  background: none;
  color: white;
  border: 0px;
  font-size: 30px;
  padding: 10px;
  transition-duration: 100ms;
  cursor: pointer;
}

.loadingButton:hover{
  color: brown;
}

.brand-information {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
  z-index: 10;
  font-size: 14px;
  text-align: center;
}

.action-link {
  text-decoration: none;
  color: #8c80d1;
  cursor: pointer;
  font-weight: bold;
}

.credits-wrapper {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.hidden {
  visibility: hidden;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  } 
}

/* Responsive Media */
/* Tablet */
@media (max-width: 1023px) {
	.loadingLabel{
    font-size: 26px;
  }
  
  .loadingButton {
    font-size: 26px;
  }
}

/* Phone */
@media (max-width: 767px) {
	.loadingLabel{
    font-size: 16px;
  }
  
  .loadingButton {
    font-size: 16px;
  }

  .sunflowerLoader {
    width: 150px;
    height: 150px;
  }
}
/* ./Responsive Media */
